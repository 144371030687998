import React from 'react';
import { Button, Box, VStack, HStack, Link as ChakraLink, Heading, Text, Flex, Spacer, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import './App.css';
import lightcone_logo from './logo_concept_1.png';
import headshot from './headshot.jpg';

import { Collapse, useDisclosure } from '@chakra-ui/react';
  

function About() {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box className="App">
      <Box
        as="nav"
        padding="1.5rem"
        bg="black"
        color="white"
      >
        <Flex align="center" justify="space-between" wrap="wrap">
          <Flex align="center" mr={5}>
            <Image src={lightcone_logo} alt="Lightcone Logo" maxHeight="50px" />
            <Link to="/">
              <Heading as="h1" size="lg" ml={4}>
                Lightcone Asset Management
              </Heading>
            </Link>
          </Flex>
          <Spacer />
          <HStack spacing={4}>
            <Link to="/about">
                <Button 
                  bg="black" 
                  color="rgba(0, 255, 255, 0.7)" 
                  border="2px solid rgba(0, 255, 255, 0.7)"
                  _hover={{ 
                    bg: "black", 
                    color: "rgba(255, 105, 180, 0.7)",
                    border: "2px solid rgba(255, 105, 180, 0.7)"
                  }}
                >
                  About
                </Button>
            </Link>
            <Link to="/contact">
                <Button 
                  bg="black" 
                  color="rgba(255, 105, 180, 0.7)" 
                  border="2px solid rgba(255, 105, 180, 0.7)"
                  _hover={{ 
                    bg: "black", 
                    color: "rgba(0, 255, 255, 0.7)",
                    border: "2px solid rgba(0, 255, 255, 0.7)"
                  }}
                >
                  Contact Us
                </Button>
            </Link>
            <Link to="/data">  
              <Button 
                bg="black" 
                color="rgba(0, 255, 255, 0.7)" 
                border="2px solid rgba(0, 255, 255, 0.7)"
                _hover={{ 
                  bg: "black", 
                  color: "rgba(255, 105, 180, 0.7)",
                  border: "2px solid rgba(255, 105, 180, 0.7)"
                }}
              >
                Research
              </Button>
            </Link>
          </HStack>
        </Flex>
      </Box>
      <main style={{ backgroundColor: 'black', color: 'white', padding: '40px 0' }}>
        <VStack spacing={8} align="center" mt={8}>
          <Image src={headshot} alt="Daniel Bassett" maxWidth="200px" borderRadius="full" />
          <Heading as="h2" size="xl" color="white">Daniel S. Bassett</Heading>
          <Heading as="h3" size="md" color="gray.400">Founder, CEO</Heading>
          <Text fontSize="xl" textAlign="center" maxW="600px" color="gray.200">
            Daniel Bassett is the founder and CEO of Lightcone. He leads the investment strategy, development and execution of trading for the firm.
          </Text>
        </VStack>
      </main>
      <footer style={{ backgroundColor: 'black', color: 'white', padding: '20px 0' }}>
        <HStack spacing={4} justify="center" mt={8} mb={4}>
          <Text color="white">Miami, FL, USA</Text>
        </HStack>
        <Text textAlign="center" fontSize="sm" color="gray.500">
          &copy; {new Date().getFullYear()} Lightcone Trading LLC
        </Text>
        <Box maxW="800px" mx="auto" mt={4}>
          <Text textAlign="center" fontSize="xs" color="gray.500" lineHeight="1.4">
            PRIVACY NOTICE<br />
            LIGHTCONE ASSET MANAGEMENT LLC<br />
            Website: https://www.lightconemgmt.com/<br /><br />

            June 2024<br /><br />

            Lightcone Asset Management LLC appreciates the trust our clients place in us and recognizes the importance of protecting the confidentiality of non-public personal information. We collect this information to ensure accuracy in reporting and record keeping, to perform our regular course of business, and to comply with the law and our regulators.
          </Text>
          <Button onClick={onToggle} size="sm" mt={2} variant="link" color="gray.500">
            {isOpen ? "Read Less" : "Read More"}
          </Button>
          <Collapse in={isOpen} animateOpacity>
            <Text textAlign="center" fontSize="xs" color="gray.500" mt={2} lineHeight="1.4">
              Investment advisers are required by federal law to adopt written policies and procedures that address administrative, technical, and physical safeguards for the protection of client records and the proper disposal of such information. Furthermore, investment advisers are required to inform their clients of these specific policies regarding privacy of information. Lightcone Asset Management LLC ("Lightcone Asset Management" the "Company" or "Firm") appreciates the trust our clients place in us, and we recognize the importance of protecting the confidentiality of non-public personal information that we collect from them through our business relationships. The information is used to ensure accuracy in reporting and record keeping, to perform our regular course of business, and to comply with the law and our regulators.<br /><br />

              Keeping this information secure is a top priority for us, and we are pleased to share with you our Privacy Policy:<br /><br />

              We collect non-public personal information about our clients from the following sources:<br /><br />

              Applications (e.g., due diligence questionnaires, investor forms, account applications)<br />
              Investment Management Agreement with Sponsor<br />
              Broker Dealer Agreement<br />
              Custodian Agreement<br />
              Other forms, correspondence, and communications<br /><br />

              The information we collect can include:<br /><br />

              Name, address, social security number, and date of birth<br />
              Assets, income, accredited investor status, and investment objectives<br />
              Transactions and investments with us (account values, transactions, etc.)<br />
              Other information useful to our services<br /><br />

              We maintain administrative, physical and electronic safeguards to protect non-public personal information.<br /><br />

              We may disclose non-public personal information about our clients and former clients to employees, independent contractors, or other third parties with whom we have contracted to perform services on our behalf, such as brokerage, legal, accounting, compliance, and data processing services, as well as in order to comply with legal and regulatory requests made to us, and to assist with law enforcement, investigations, complaints, regulatory requests, litigation, arbitration, mediation, and other legal processes.<br /><br />

              Note that our Privacy Policy includes no right to disseminate non-public personal information about our clients and former clients to any external or third party for marketing or other purposes not directly related to servicing your investor account.<br /><br />

              We may disclose non-public personal information about our clients and former clients as required by federal, state, or local law. We will provide notice of changes in our information sharing practices. If, at any time in the future, it is necessary to disclose any of your personal information in a way that is inconsistent with this policy, we will give you advance notice of the proposed change so you will have the opportunity to opt-out of such disclosure.<br /><br />

              Other Important Information:<br />
              For California residents: We will not share information we collect about you with nonaffiliated third parties, except as permitted by law. We will not share information about your creditworthiness with our affiliates, as applicable, other than as permitted by California law, unless you authorize us to make those disclosures.<br /><br />

              For Nevada clients: Section 340 of the Nevada Privacy Law requires that an operator post a privacy policy, which is called a "notice" in Nevada. We are providing you with this notice pursuant to state law. We will not share information we collect about you with nonaffiliated third parties, except as permitted by law. We will not share information about your creditworthiness with our affiliates, as applicable, other than as permitted by Nevada law, unless you authorize us to make those disclosures.<br /><br />

              For Vermont residents: We will not share information we collect about you with nonaffiliated third parties, except as permitted by law, including, for example with your consent or to service your account. We will not share information about your creditworthiness with our affiliates, other than as permitted by Vermont law, unless you authorize us to make those disclosures.<br />
              Lightcone Asset Management is an Exempt Reporting Adviser ("ERA") under Texas state jurisdiction, which is an investment adviser that is not required to register as investment adviser with the Securities and Exchange Commission ("SEC") because it relies on certain exemptions from registration under sections 203(l) and 203(m) of the Investment Advisers Act of 1940 and related rules. The Firm is committed to safeguarding the confidential information of our clients. The Company is keenly aware that cybersecurity and privacy breaches may occur as a result of security failures at third parties that have access to the Company's systems. To minimize the risk of such events, the Company has adopted the policies and procedures. A designated principal of Aetas Global Management shall ensure that any breach, misappropriation or loss of such information is immediately reported to the individual(s) whose information was compromised and any applicable state or federal regulatory or law enforcement agencies. In addition, we intend to notify clients of any material breaches that might put your personal financial data at risk. If, at any time in the future, it is necessary to disclose any of your personal information in a way that is inconsistent with this policy, we will give you advance notice of the proposed change so you will have the opportunity to opt out of such disclosure. We will provide notice of changes in our information sharing practices.<br /><br />

              We want to hear from you if you have questions.<br /><br />

              Mr. Daniel Bassett<br />
              Chief Executive Officer
            </Text>
          </Collapse>
        </Box>
      </footer>
    </Box>
  );
}

export default About;