import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import './App.css';
import Home from './home';
import Contact from './contact';
import Data from './data';  // Import the Data component
import About from './about';

function App() {
  return (
    <ChakraProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/data" element={<Data />} /> 
          </Routes>
        </div>
      </Router>
    </ChakraProvider>
  );
}

export default App;
